import React, { FC } from 'react';

import RichText from '@elements/RichText';
import CaseStudyList from '@sections/CaseStudyList';
import Hero from '@sections/Hero';
import ModuleSelector from '@sections/ModuleSelector';
import { graphql } from 'gatsby';

import '../styles/templates/caseStudy.css';

type CommonContentfulProps = {
  __typename: string;
  id: string;
}

type PageProps = {
  data: {
    caseStudy: CommonContentfulProps & {
      contentful_id: string;
      title: string;
      topSection: CommonContentfulProps[]
      topList: CommonContentfulProps;
      middleList: CommonContentfulProps;
      bottomSection: {
        raw: string;
      };
      modules: any[];
    }
  },
}

const CaseStudy: FC<PageProps> = ({ data }) => {
  const { caseStudy } = data;
  // eslint-disable-next-line no-underscore-dangle
  const heroItem = caseStudy.topSection?.find(oneModule => oneModule.__typename === "ContentfulHero");

  return (
    <>
      {heroItem && (
        <div className="case-study--top-section">
          <Hero item={heroItem} />
        </div>
      )}
      <CaseStudyList itemsList={caseStudy.topList} type="top" />
      <CaseStudyList itemsList={caseStudy.middleList} type="middle" />
      {caseStudy?.bottomSection && (
        <div className="case-study--bottom-section">
          <RichText bodyRichText={caseStudy.bottomSection} />
        </div>
      )}
      {caseStudy?.modules && (
        <ModuleSelector modules={caseStudy.modules} />
      )}
    </>
  );
};

export default CaseStudy;

export const pageQuery = graphql`
  query QUERY_CASE_STUDY (
    $contentful_id: String!
  ) {

    layout: contentfulNavbarAndFooterConfiguration {
      ...LayoutItems
    }

    seo: contentfulCaseStudy(contentful_id: { eq: $contentful_id }) {
      ...CaseStudySeo
    }

    caseStudy: contentfulCaseStudy(contentful_id: { eq: $contentful_id }) {
      ...CaseStudyFull
    }

  }
`;
